/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, useEffect, useState } from 'react';
import { MapPinIcon, PhoneIcon } from '@heroicons/react/20/solid';
import { useQuery } from 'react-query';
import { LocateOff } from 'lucide-react';
import { Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import utils from '../../../common/services/utils.service';
import { ThemeContext } from '../../../../contexts/theme.context';
import { TBrandDetails, TCity, TMerchantBranch } from '../../../common/types';
import useCurrentUser from '../../../hooks/useCurrentUser.hook';
import useOrderCreationStore from '../../../hooks/useOrderCreation.hook';
import { useIsLoading } from '../../../common/hooks';
import headerApi from '../../../header/header.api';
import sharedApi from '../../../common/shared.api';
import { Select } from '../../../common/components';

type TPickupProps = {
  fetchBranchAndUpdateMenu: (
    branchId: number,
    facilityId: number,
    marketplaceId: number,
    brandId: number,
    fulfillmentId: number,
    customerAddressId?: number,
    customerAddress?: string,
  ) => Promise<void>;
  cities: TCity[];
  triggerMobileValidation: () => void;
};

const Pickup: FC<TPickupProps> = ({ fetchBranchAndUpdateMenu, cities, triggerMobileValidation }) => {
  const { theme } = useContext(ThemeContext);
  const { setIsLoading } = useIsLoading();
  const { currentUser } = useCurrentUser();
  const { orderCreationStore, setOrderCreationStore, reset } = useOrderCreationStore();
  const { orderTicket } = orderCreationStore;
  const [selectedCity, setSelectedCity] = useState<number>(1);
  const [merchantBranches, setMerchantBranches] = useState<TMerchantBranch[]>([]);
  const brandDetails: TBrandDetails | null = JSON.parse(localStorage.getItem('brandDetails') || 'null');
  const [selectedPickupLocation, setSelectedPickupLocation] = useState<TMerchantBranch | null>(null);
  const [branchId, setBranchId] = useState<number>(0);
  const navigate = useNavigate();

  const fetchMerchantBranches = useQuery(
    'fetchMerchantBranches',
    () =>
      sharedApi.getMerchantBranches(
        {
          city_id: selectedCity,
          brand_id: orderCreationStore.orderTicket.integration_details.brand_id,
        },
        brandDetails?.merchant.merchant_id || 0,
      ),
    {
      enabled: false,
      onSuccess: ({ data }) => {
        setMerchantBranches(data.data);
        if (orderTicket?.integration_details.branch_id) {
          const selectedLocation = data.data.find(
            (location) => location.branch_id === orderTicket?.integration_details.branch_id,
          );
          setSelectedPickupLocation(selectedLocation || null);
        }
      },
    },
  );

  const menuQuery = useQuery('menu', async () => headerApi.getMenu(Number(branchId)), {
    enabled: false,
    onSuccess: async ({ data: queryData, status }) => {
      if (queryData && status === 200) {
        const updatedMenu = queryData.data?.map((category) => ({
          ...category,
          menu_items: category.menu_items?.map((item) => ({
            ...item,
            item_category_id: category.item_category_id,
          })),
        }));
        setOrderCreationStore({
          ...orderCreationStore,
          menu: updatedMenu,
        });
        navigate('/menu');
        setIsLoading(false);
      }
    },
  });

  useEffect(() => {
    if (currentUser?.merchant.merchant_id) fetchMerchantBranches.refetch();
  }, []);

  useEffect(() => {
    setIsLoading(fetchMerchantBranches.isFetching);
  }, [fetchMerchantBranches.isFetching]);

  return (
    <div className="p-2" style={{ backgroundColor: theme?.general?.backgroundColor || '' }}>
      <div className="flex justify-between py-2">
        <h1 className="text-lg font-semibold text-primary" style={{ color: theme?.general?.textColor || 'gray' }}>
          Select Pickup Location
        </h1>
        {selectedCity && cities.length > 0 ? (
          <Select
            options={cities.map((city) => ({
              label: city.city_name,
              value: city.city_id,
            }))}
            value={selectedCity || 0}
            onChange={(value) => {
              setSelectedCity(Number(value));
              setTimeout(() => fetchMerchantBranches.refetch(), 100);
            }}
            placement="right"
            placeholder="Select City"
          />
        ) : null}
      </div>
      <div className="flex flex-col gap-2">
        {merchantBranches.length === 0 ? (
          <div className="h-full flex gap-0 flex-col justify-center items-center">
            <LocateOff width={68} height={68} color={theme?.general?.textColor || 'text-gray-400'} />
            <h1 className="text-xl font-bold" style={{ color: theme?.general?.textColor || '' }}>
              No Pickup Location found in selected city
            </h1>
          </div>
        ) : null}
        {merchantBranches.map((location) => (
          <button
            type="button"
            key={location.branch_id}
            className={utils.cn('border rounded-md px-2 py-1 shadow-md text-left', {
              'border-colorPrimary border-2': selectedPickupLocation?.branch_id === location.branch_id,
            })}
            style={{ backgroundColor: theme?.general?.cardBackgroundColor || '' }}
            onClick={() => {
              setSelectedPickupLocation(location);
            }}
          >
            <h1
              className="text-base font-semibold text-primary capitalize"
              style={{ color: theme?.general?.textColor || 'text-gray-400' }}
            >
              {location.facility.facility_name}
            </h1>
            <h2
              className="text-sm flex gap-1 capitalize"
              style={{ color: theme?.general?.textColor || 'text-gray-400' }}
            >
              <MapPinIcon className="w-4 h-4" /> {location.facility.address_line_1 || 'N/A'}
            </h2>
            <h3 className="text-sm flex gap-1" style={{ color: theme?.general?.textColor || 'text-gray-400' }}>
              <PhoneIcon className="w-4 h-4" /> {location.facility.facility_contact_phone_nr || 'N/A'}
            </h3>
            {/* <h3 className="text-sm flex gap-1">
              <ClockIcon className="w-4 h-4" />
              {location.facility.} - {location.closingHours}
            </h3> */}
          </button>
        ))}
      </div>
      <div className="flex justify-center mt-4">
        {!currentUser ? (
          <button
            type="button"
            className="px-2 w-max py-2 m-1 text-white bg-colorSecondary rounded-md flex justify-center items-center disabled:opacity-50 disabled:pointer-events-none"
            style={{ backgroundColor: theme?.general?.buttonColor || '' }}
            onClick={() => triggerMobileValidation()}
            disabled={!!currentUser}
          >
            Verify your phone number to select different pickup location
          </button>
        ) : (
          <button
            type="button"
            className="px-2 py-2 m-1 text-white bg-colorPrimary rounded-md hover:bg-colorSecondary flex justify-center items-center disabled:opacity-50 disabled:pointer-events-none"
            style={{ backgroundColor: theme?.general?.buttonColor || '' }}
            disabled={!selectedPickupLocation}
            onClick={() => {
              if (selectedPickupLocation?.branch_id !== orderCreationStore.orderTicket.integration_details.branch_id) {
                Modal.confirm({
                  title: 'Change Branch',
                  content: 'You are about to change the store, any items in bucket will be reset. Are you sure?',
                  onOk: () => {
                    if (!selectedPickupLocation) return;
                    setIsLoading(true);
                    setBranchId(selectedPickupLocation?.branch_id || 0);
                    const updatedStore: any = reset();
                    setTimeout(() => {
                      setOrderCreationStore({
                        ...updatedStore,
                        orderTicket: {
                          ...updatedStore.orderTicket,
                          customer_address_details: undefined,
                          integration_details: {
                            ...updatedStore.orderTicket.integration_details,
                            facility_id: selectedPickupLocation?.facility.facility_id,
                            branch_id: selectedPickupLocation?.branch_id,
                            brand_id: selectedPickupLocation?.brand.brand_id,
                            marketplace_id: selectedPickupLocation?.marketplace.marketplace_id,
                            fulfillment_type: 'pickup',
                            fulfillment_type_id: 4,
                          },
                        },
                        address: {
                          order_address: selectedPickupLocation?.facility.facility_name,
                        },
                      });
                      menuQuery.refetch();
                    }, 100);
                  },
                });
              } else {
                fetchBranchAndUpdateMenu(
                  selectedPickupLocation?.branch_id || 0,
                  selectedPickupLocation?.facility.facility_id || 0,
                  selectedPickupLocation?.marketplace.marketplace_id || 0,
                  selectedPickupLocation?.brand.brand_id || 0,
                  4,
                  undefined,
                  selectedPickupLocation?.facility.facility_name || '',
                );
              }
            }}
          >
            Confirm Pickup Location
          </button>
        )}
      </div>
    </div>
  );
};

export default Pickup;
