/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-extraneous-dependencies */
import Map, { MapRef, Marker, ViewState, ViewStateChangeEvent } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { Tooltip, message, Modal as AntModal, AutoComplete } from 'antd';
import mbxGeocoding, { GeocodeFeature } from '@mapbox/mapbox-sdk/services/geocoding';
import { FC, useContext, useEffect, useRef, useState } from 'react';
import { MapPinIcon } from '@heroicons/react/20/solid';
import { ChevronLeft, MapPinOff, MapPinned } from 'lucide-react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { mapBoxToken } from '../../../../config/variables/system.variable';
import { ThemeContext } from '../../../../contexts/theme.context';
import { Modal } from '../../../common/components';
import utils, { cn, debounce } from '../../../common/services/utils.service';
import orderTypeApi, { TAddAddressPayload } from '../../order-type.api';
import useCurrentUser from '../../../hooks/useCurrentUser.hook';
import { TCity, TCustomerAddress, TMerchantBranch } from '../../../common/types';
import { useIsLoading } from '../../../common/hooks';
import useOrderCreationStore from '../../../hooks/useOrderCreation.hook';
import headerApi from '../../../header/header.api';
import sharedApi from '../../../common/shared.api';

type TDeliveryProps = {
  fetchBranchAndUpdateMenu: (
    branchId: number,
    facilityId: number,
    marketplaceId: number,
    brandId: number,
    fulfillmentId: number,
    customerAddressId?: number,
    customerAddress?: string,
  ) => Promise<void>;
  cities: TCity[];
  triggerMobileValidation: () => void;
};

const getAddressType = (item: string) => {
  switch (item) {
    case 'home':
      return 1;
    case 'office':
      return 2;

    case 'other':
      return 3;

    default:
      return 4;
  }
};

const initialAddress: TAddAddressPayload = {
  address_line_1: '',
  address_line_2: '',
  address_type_id: 1,
  city_id: 1,
  customer_id: 1,
  delivery_instructions: '',
  latitude: '',
  longitude: '',
};

const Delivery: FC<TDeliveryProps> = ({ fetchBranchAndUpdateMenu, cities, triggerMobileValidation }) => {
  const { theme } = useContext(ThemeContext);
  const { setIsLoading } = useIsLoading();
  const { currentUser } = useCurrentUser();
  const { orderCreationStore, setOrderCreationStore, reset } = useOrderCreationStore();
  const geocodingClient = mbxGeocoding({ accessToken: mapBoxToken || '' });
  const [newAddress, setNewAddress] = useState<TAddAddressPayload>(initialAddress);
  const [addressList, setAddressList] = useState<TCustomerAddress[]>([]);
  const [isAddAddressModalVisible, setIsAddAddressModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState<'savedLocations' | 'newLocation'>('savedLocations');
  const [selectedDeliveryLocation, setSelectedDeliveryLocation] = useState<TCustomerAddress | null>(null);
  const [viewport, setViewport] = useState<
    ViewState & {
      width: number;
      height: number;
    }
  >({
    latitude: 25.07407662691456,
    longitude: 55.186728012070375,
    zoom: 11.454236564204464,
    width: 100,
    height: 100,
    bearing: 0,
    pitch: 0,
    padding: { left: 0, right: 0, top: 0, bottom: 0 },
  });
  const [marker, setMarker] = useState<{ latitude: number; longitude: number } | null>({
    latitude: 25.07407662691456,
    longitude: 55.186728012070375,
  });
  const [branchId, setBranchId] = useState<number>();
  const [mapSearchResults, setMapSearchResults] = useState<GeocodeFeature[]>([]);
  const mapRef = useRef<MapRef>(null);
  const navigate = useNavigate();

  const fetchAddresses = useQuery(
    'addresses',
    () => orderTypeApi.getAddresses(currentUser?.customer.customer_id || 0),
    {
      enabled: false,
      onSuccess: ({ data, status }) => {
        if (status === 200) {
          setAddressList(data.data.customer_address_list);
          if (orderCreationStore?.orderTicket?.customer_address_details?.customer_address_id) {
            const selectedLocation = data.data.customer_address_list.find(
              (location) =>
                location.customer_address_id ===
                orderCreationStore.orderTicket.customer_address_details?.customer_address_id,
            );
            setSelectedDeliveryLocation(selectedLocation || null);
          }
        }
      },
    },
  );

  const menuQuery = useQuery('menu', async () => headerApi.getMenu(Number(branchId)), {
    enabled: false,
    onSuccess: async ({ data: queryData, status }) => {
      if (queryData && status === 200) {
        const updatedMenu = queryData.data?.map((category) => ({
          ...category,
          menu_items: category.menu_items?.map((item) => ({
            ...item,
            item_category_id: category.item_category_id,
          })),
        }));
        setOrderCreationStore({
          ...orderCreationStore,
          menu: updatedMenu,
        });
        navigate('/menu');
        setIsLoading(false);
      }
    },
  });

  const handleViewportChange = (e: ViewStateChangeEvent) => {
    setViewport({ ...e.viewState, width: 100, height: 100 });
    setMarker({
      latitude: e.viewState.latitude,
      longitude: e.viewState.longitude,
    });
  };

  const onConfirmNewAddress = async () => {
    const { status } = await orderTypeApi.addAddress(newAddress);
    if (status === 200) {
      fetchAddresses.refetch();
      setCurrentPage('savedLocations');
    } else {
      message.error('Failed to add address');
    }
    setIsAddAddressModalVisible(false);
  };

  const searchLocation = async (query: string) => {
    const response = await geocodingClient
      .forwardGeocode({
        query,
        limit: 30,
        language: ['en'],
        countries: ['ae'],
      })
      .send();

    console.log('Search location response:', response);
    console.log('Search location response features:', response.body.features);

    setMapSearchResults(response.body.features);
    console.log('Map search results:', mapSearchResults);
  };

  const debouncedHandleViewportChange = debounce(handleViewportChange, 0.5);
  const debouncedSetViewport = debounce(
    (e: ViewStateChangeEvent) => setViewport({ ...e.viewState, width: 100, height: 100 }),
    0.5,
  );

  const onConfirmLocation = async () => {
    // Reverse geocoding
    if (marker) {
      const response = await geocodingClient
        .reverseGeocode({
          query: [marker.longitude, marker.latitude],
          limit: 1,
          language: ['en'],
        })
        .send();

      const city = response.body.features[0].context.find((context) => context.id.includes('place'))?.text;
      const cityId = cities.find((c) => c.city_name === city)?.city_id;

      const address = response.body.features[0].place_name;
      const addressWithoutArabic = address.replace(/[\u0600-\u06FF]/g, '');
      const cleanedAddress = addressWithoutArabic.replace(/^,| ,/g, '').trim();

      setNewAddress({
        ...newAddress,
        customer_id: currentUser?.customer.customer_id,
        address_line_1: cleanedAddress,
        address_type_id: getAddressType('home'),
        city_id: cityId || 1,
        latitude: String(marker.latitude),
        longitude: String(marker.longitude),
      });

      setIsAddAddressModalVisible(true);
    }
  };

  const confirmDeliveryLocation = async (location: TCustomerAddress) => {
    setIsLoading(true);
    const { data, status } = await sharedApi.getMerchantBranches(
      {
        latitude: location.latitude,
        longitude: location.longitude,
        brand_id: orderCreationStore.orderTicket.integration_details.brand_id,
        fulfillment_type_id: 3,
      },
      currentUser?.merchant.merchant_id || 0,
    );
    let possibleBranch: TMerchantBranch | null = null;

    if (status === 200) {
      possibleBranch =
        data.data.find(
          (branch) => Number(branch.facility.delivery_range_km) - Number(branch.facility.cand_distance_km) > 0,
        ) || null;
    }

    if (possibleBranch) {
      if (possibleBranch.branch_id !== orderCreationStore.orderTicket.integration_details.branch_id) {
        AntModal.confirm({
          title: 'Change Branch',
          content: 'You are about to change the store, any items in bucket will be reset. Are you sure?',
          onOk: () => {
            setIsLoading(true);
            setBranchId(possibleBranch?.branch_id);
            const updatedStore: any = reset();
            setTimeout(() => {
              setOrderCreationStore({
                ...updatedStore,
                orderTicket: {
                  ...updatedStore.orderTicket,
                  customer_address_details: {
                    customer_address_id: location.customer_address_id,
                    customer_address: location.address_line_1,
                  },
                  integration_details: {
                    ...updatedStore.orderTicket.integration_details,
                    facility_id: possibleBranch?.facility.facility_id,
                    branch_id: possibleBranch?.branch_id,
                    brand_id: possibleBranch?.brand.brand_id,
                    marketplace_id: possibleBranch?.marketplace.marketplace_id,
                    fulfillment_type: 'restaurant-delivery',
                    fulfillment_type_id: 3,
                  },
                },
                address: {
                  order_address: location.address_line_1,
                },
              });
              menuQuery.refetch();
            }, 100);
          },
        });
      } else {
        fetchBranchAndUpdateMenu(
          orderCreationStore.orderTicket.integration_details.branch_id,
          orderCreationStore.orderTicket.integration_details.facility_id,
          orderCreationStore.orderTicket.integration_details.marketplace_id || 0,
          orderCreationStore.orderTicket.integration_details.brand_id,
          3,
          selectedDeliveryLocation?.customer_address_id,
          selectedDeliveryLocation?.address_line_1,
        );
      }
    } else {
      AntModal.info({
        title: 'Unavailable',
        content: `We’re not there yet`,
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (currentUser) {
      fetchAddresses.refetch();
    }
  }, [currentUser]);

  useEffect(() => {
    setIsLoading(fetchAddresses.isFetching);
  }, [fetchAddresses.isFetching]);

  return (
    <div className="h-[80vh]" style={{ backgroundColor: theme?.general?.backgroundColor || '' }}>
      {currentPage === 'savedLocations' ? (
        <div className="h-full">
          {addressList.length === 0 ? (
            <div className="h-full flex gap-0 flex-col justify-center items-center">
              <MapPinOff width={68} height={68} color={theme?.general?.textColor || 'text-gray-400'} />
              <h1 className="text-xl font-bold" style={{ color: theme?.general?.textColor || '' }}>
                No Previous address found
              </h1>
              <h2 className="text-gray-400 font-normal" style={{ color: theme?.general?.textColorSecondary || '' }}>
                You don&apos;t have any addresses saved yet.
              </h2>
              <h2 className="text-gray-400 font-normal">
                {currentUser ? 'Press on the button to add one' : 'Verify your phone number to add new Location'}
              </h2>
              {!currentUser ? (
                <button
                  type="button"
                  className="px-2 w-max py-2 m-1 text-white bg-colorSecondary rounded-md flex justify-center items-center disabled:opacity-50 disabled:pointer-events-none"
                  style={{ backgroundColor: theme?.general?.buttonColor || '' }}
                  onClick={() => triggerMobileValidation()}
                >
                  Verify your phone number
                </button>
              ) : (
                <button
                  type="button"
                  className="px-2 w-max py-2 m-1 mt-4 text-white bg-colorSecondary rounded-md flex justify-center items-center disabled:opacity-50 disabled:pointer-events-none"
                  style={{ backgroundColor: theme?.general?.buttonColor || '' }}
                  onClick={() => setCurrentPage('newLocation')}
                  disabled={!currentUser}
                >
                  Add new Location
                </button>
              )}
            </div>
          ) : (
            <div className="p-2">
              <div className="flex flex-col gap-3">
                {addressList.map((location) => (
                  <button
                    type="button"
                    key={location.customer_address_id}
                    className={utils.cn('flex gap-2 w-full items-center border-2 rounded-md p-2 shadow-md', {
                      'border-colorPrimary':
                        selectedDeliveryLocation?.customer_address_id === location.customer_address_id,
                    })}
                    onClick={() => setSelectedDeliveryLocation(location)}
                  >
                    <div className="flex flex-col gap-0 justify-center items-start">
                      <div className="text-lg font-semibold flex gap-1 capitalize">
                        <MapPinned width={20} /> {location.address_type_name}
                      </div>
                      <div className="text-sm text-left">{location.address_line_1}</div>
                    </div>
                  </button>
                ))}
              </div>
              <div className="flex justify-between gap-2 mt-4">
                <button
                  type="button"
                  className="px-2 w-full py-2 m-1 text-white bg-colorPrimary rounded-md hover:bg-colorSecondary flex justify-center items-center disabled:opacity-50 disabled:pointer-events-none"
                  style={{ backgroundColor: theme?.general?.buttonColor || '' }}
                  disabled={!selectedDeliveryLocation}
                  onClick={() => selectedDeliveryLocation && confirmDeliveryLocation(selectedDeliveryLocation)}
                >
                  Confirm Delivery Location
                </button>
                {!currentUser ? (
                  <button
                    type="button"
                    className="px-2 w-full py-2 m-1 text-white bg-colorSecondary rounded-md flex justify-center items-center disabled:opacity-50 disabled:pointer-events-none"
                    style={{ backgroundColor: theme?.general?.buttonColor || '' }}
                    onClick={() => triggerMobileValidation()}
                  >
                    Verify your phone number to add new Location
                  </button>
                ) : (
                  <button
                    type="button"
                    className="px-2 w-full py-2 m-1 text-white bg-colorSecondary rounded-md flex justify-center items-center disabled:opacity-50 disabled:pointer-events-none"
                    style={{ backgroundColor: theme?.general?.buttonColor || '' }}
                    onClick={() => setCurrentPage('newLocation')}
                    disabled={!currentUser}
                  >
                    Add new Location
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      ) : null}
      {currentPage === 'newLocation' ? (
        <div className="relative" style={{ height: '85vh', width: '100%' }}>
          <button
            type="button"
            className="absolute top-0 left-0 pt-1.5 flex gap-0 text-black text-base font-semibold items-center z-20"
            onClick={() => setCurrentPage('savedLocations')}
          >
            <ChevronLeft color="black" size={22} />
            Back
          </button>

          <Map
            ref={mapRef}
            mapboxAccessToken={mapBoxToken}
            initialViewState={viewport}
            viewState={viewport} // Replace 100 with the desired width and height values
            style={{ width: '100%', height: '100%' }}
            mapStyle="mapbox://styles/mapbox/light-v8" // "mapbox://styles/mapbox/dark-v11"
            interactiveLayerIds={['data']}
            onMove={debouncedHandleViewportChange}
            onZoomEnd={debouncedSetViewport}
          >
            {marker ? (
              <Marker latitude={marker.latitude} longitude={marker.longitude}>
                <Tooltip>
                  <MapPinIcon className="w-8 h-8 text-colorSecondary mb-4" />
                </Tooltip>
              </Marker>
            ) : null}
          </Map>
          <AutoComplete
            placeholder="Search for location"
            options={mapSearchResults.map((result) => ({
              label: result.place_name,
              value: result.place_name,
            }))}
            onChange={(value) => {
              const selectedLocation = mapSearchResults.find((result) => result.place_name === value);
              if (selectedLocation) {
                setViewport({
                  ...viewport,
                  latitude: selectedLocation.center[1],
                  longitude: selectedLocation.center[0],
                  zoom: 14,
                });
                setMarker({
                  latitude: selectedLocation.center[1],
                  longitude: selectedLocation.center[0],
                });
              }
            }}
            className="absolute top-0.5 right-0.5 w-4/5"
            onSearch={debounce((value) => {
              console.log('On search:', value);
              if (!value || value === '') return;
              searchLocation(value);
            }, 500)}
          />
          <div className="absolute bottom-6 z-10 w-full flex justify-center">
            <button
              type="button"
              className="w-4/5 py-3 m-1 text-white bg-colorPrimary rounded-md text-lg font-semibold flex justify-center items-center disabled:opacity-50 disabled:pointer-events-none"
              style={{ backgroundColor: theme?.general?.buttonColor || '' }}
              onClick={() => onConfirmLocation()}
              // onClick={() => searchLocation()}
            >
              USE THIS LOCATION
            </button>
          </div>
        </div>
      ) : null}
      <Modal
        title="Address Details"
        onClose={() => setIsAddAddressModalVisible(false)}
        open={isAddAddressModalVisible}
        footer={null}
        className="md:w-1/3"
      >
        <div className="flex flex-col gap-4 justify-start text-left">
          <div>Please confirm the address</div>
          <div className="flex gap-1">
            {['home', 'office', 'other'].map((type) => (
              <button
                key={type}
                type="button"
                className={cn('py-2 px-4 rounded-full capitalize bg-gray-100', {
                  'bg-colorPrimary text-white': getAddressType(type) === newAddress.address_type_id,
                })}
                style={{
                  backgroundColor:
                    (getAddressType(type) === newAddress.address_type_id && theme?.general?.buttonColor) || '',
                }}
                onClick={() => {
                  setNewAddress({
                    ...newAddress,
                    address_type_id: getAddressType(type),
                  });
                }}
              >
                {type}
              </button>
            ))}
          </div>
          <textarea
            onChange={(e) => {
              setNewAddress({
                ...newAddress,
                address_line_1: e.target.value,
              });
            }}
            rows={3}
            className="rounded-md"
            defaultValue={newAddress.address_line_1}
            value={newAddress.address_line_1}
            placeholder="Enter Address Name"
          />

          <button
            type="button"
            onClick={() => onConfirmNewAddress()}
            className="bg-colorPrimary text-center rounded-full py-2 text-textPrimary text-lg font-semibold"
            style={{ backgroundColor: theme?.general?.buttonColor || '' }}
          >
            Confirm
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default Delivery;
